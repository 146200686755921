var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{},[_c('label',[_vm._v(_vm._s(_vm.label)+" "),(_vm.optional)?_c('i',[_vm._v(_vm._s(_vm.$t(" - Optional")))]):_vm._e()])]),_c('tinymce-editor',{class:{ 'hideToolbar' : _vm.hideToolbar },attrs:{"api-key":"mbjj7thp2i1job3hpnme5jih6uxb4ut63qx8lcx18g8ea5t4","init":{
       height: 300,
       menubar: false,
       block_formats: 'Heading=h4;Paragraph=p',
       plugins: [
         'advlist autolink lists link image charmap print preview anchor',
         'searchreplace visualblocks code fullscreen',
         'insertdatetime media table paste code help wordcount emoticons'
       ],
       paste_as_text: true,
       resize: false,
       toolbar:
         'undo redo | formatselect | bold italic |  \
         bullist numlist | removeformat'
     }},model:{value:(_vm.contentVal),callback:function ($$v) {_vm.contentVal=$$v},expression:"contentVal"}}),(_vm.errors)?_c('AppError',{attrs:{"errors":_vm.errors,"field":_vm.field}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }